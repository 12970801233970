import { render, staticRenderFns } from "./EventScoreboardRow.vue?vue&type=template&id=fa858a78&scoped=true&"
import script from "./EventScoreboardRow.vue?vue&type=script&lang=js&"
export * from "./EventScoreboardRow.vue?vue&type=script&lang=js&"
import style0 from "./EventScoreboardRow.vue?vue&type=style&index=0&id=fa858a78&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa858a78",
  null
  
)

export default component.exports