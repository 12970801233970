//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
name: 'EventScoreboardRow',
props:['data','round'],
}
