//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmptyMessage from '@/components/EmptyMessage'
import EventScoreboardRow from '@/components/Event/EventScoreboardRow'
export default {
    name:'EventScoreboard',
    props:['isOwner','id'],
    components:{ EventScoreboardRow, EmptyMessage },
    data (){
        return {
            columns: [
                '1','2','3','4','5','6','7','8','9','OUT','10','11','12','13','14','15','16','17','18','IN','TOT'
            ],
            holes: [
                '1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'
            ],
            update_hole:null,
            update_par: null,
            update_round: null,
            pars: [],
            score_data:[],
            isPlayer: false,
            isEmpty: true,
            totals: [],
            rounds: [],
            isLoading: true,
            rounds_arr: [],
        }
    },
    methods:{
        updateScore(){
            this.payload = {
                hole: this.update_hole,
                round: this.update_round,
                id: this.id,
                score: {score:this.update_par}
            }

            this.$store.dispatch('event/UpdateScoreboard', this.payload)
            .then((r) => {
                if(r.status == 'scoreboard_updated'){
                    this.$q.notify({
                        message:'Successfully updated score',
                        color: 'positive'
                    })
                    this.fetchScoreboard()
                }
            })
        },

        joinScoreboard(){
            this.payload = {
                id: this.id
            }
            this.$q.dialog({
                message:'Do you want to participate as a player?',
                cancel: true,
                title: 'Match Participation'
            })
            .onOk(() =>{
                this.$store.dispatch('event/CreateScoreboard',this.payload)
                .then((r) => {
                    if(r.status == 'success'){
                        this.$q.notify({
                            message:'You joined the scoreboard.',
                            color:'positive'
                        })
                        window.location.reload();
                    }
                })
            })
            .onCancel(() =>{})
            .onDismiss(() =>{})
        },
        fetchScoreboard(){
            this.isLoading = true;
            this.payload = {
                event_id: this.id,
                hole: '',
                round: '',
            }

            this.$store.dispatch('event/FetchScoreBoard',this.payload)
            .then((r) => {
                if (r.status == 'scorecards_fetched') {
                    this.rounds = this.$store.state.event.event_scoreboard.settings.numberOfRounds;
                    this.pars = this.$store.state.event.event
                    this.CheckifPlayer()
                    this.score_data = this.$store.state.event.event_scoreboard.players
                    this.rounds_arr = [];
                    for (let i = 1; i < parseInt(this.rounds)+1 ;i++) {
                        this.rounds_arr.push(i)
                    }
                    this.totals = this.$store.state.event.event_scoreboard.settings
                    if( this.$store.state.event.event_scoreboard.players.length > 0 ){
                        this.isEmpty = false;
                    }
                } else {
                    this.rounds = []
                    this.pars = []
                    this.score_data = []
                    this.totals = []
                    this.isEmpty = true;
                }
                this.isLoading = false;
            })
        },

        CheckifPlayer(){
            this.score_data = this.$store.state.event.event_scoreboard
            if(this.score_data.players.length > 0 ){
                this.score_data.players.map((x) =>{
                if(x.player_id == this.$store.state.user.id){
                    this.isPlayer = true;
                }
            })
            }else{
                this.isPlayer = false;
            }
            
        },

        LeaveScoreboard(){
            this.payload = {
                id: this.id,
                user_id: this.$store.state.user.id
            }
            this.$q.dialog({
                title: 'Leave Scoreboard?',
                message:'Are you sure you want to leave the scoreboard?',
                cancel:true,
            })
            .onOk(() => {
                this.$store.dispatch('event/DeleteScorecard',this.payload)
                .then((r) => {
                    if(r.status == 'success'){
                        this.$q.notify({
                            message:'You have left the scoreboard.',
                            color:'positive'
                        });
                        this.isPlayer = false;
                        this.fetchScoreboard();
                    }
                })
                
            })
        }
    },
    created(){
        this.fetchScoreboard()
        this.CheckifPlayer()
    },
    computed: {
        getParTotal() {
            return parseInt(this.pars['hole_1'])
                + parseInt(this.pars['hole_2'])
                + parseInt(this.pars['hole_3'])
                + parseInt(this.pars['hole_4'])
                + parseInt(this.pars['hole_5'])
                + parseInt(this.pars['hole_6'])
                + parseInt(this.pars['hole_7'])
                + parseInt(this.pars['hole_8'])
                + parseInt(this.pars['hole_9'])
                + parseInt(this.pars['hole_10'])
                + parseInt(this.pars['hole_11'])
                + parseInt(this.pars['hole_12'])
                + parseInt(this.pars['hole_13'])
                + parseInt(this.pars['hole_14'])
                + parseInt(this.pars['hole_15'])
                + parseInt(this.pars['hole_16'])
                + parseInt(this.pars['hole_17'])
                + parseInt(this.pars['hole_18']);
        }
    }
}
